import {
	DrivenBox,
	DrivenButton,
	DrivenContainer,
	DrivenGrid,
	DrivenIcon,
	DrivenLink,
	DrivenTypography,
	IconSizes,
	IconTypes,
	IconTypes_FontAwesome,
} from '@iterateai/driven-components';
import { graphql, Script } from 'gatsby';
import { useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React from 'react';

import MarkdownContent from '../components/markdown-content';
import {
	captureCustomDataLayerEvent,
	GatsbyLinkBehavior as GatsbyLink,
} from '../components/gatsby-link-behavior';
import { SEO }  from '../components/seo';
import useIsClient from '../hooks/use-is-client';
import { useArticles } from '../hooks/use-articles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ArticleImage = ({ image, alt }) => {
	const gatsbyImage = getImage(
		image?.localFile?.childImageSharp.gatsbyImageData
	);

	if (!gatsbyImage) {
		return;
	}

	return <GatsbyImage image={gatsbyImage} alt={alt || ''} loading='eager' />;
};

const ArticleTemplate = ({ data, pageContext }) => {
	const { isClient, key } = useIsClient();
	const theme = useTheme();
	const { latestArticles } = useArticles();

	if (!isClient) return null;

	const {
		article: {
			slug,
			headline,
			subheadline,
			dateStructured,
			dateFormatted,
			body,
			image,
			imageAlt,
		},
	} = data;

	const shareUrl = `https://www.drivenbrands.com/news/${slug}/`;

	return (
		<DrivenContainer>
			<DrivenGrid
				container
				justifyContent='space-between'
				my={{ xs: 3, sm: 4, md: 6 }}
			>
				<DrivenGrid item md={8}>
					<DrivenBox
						mb={4}
						sx={{
							'.icon-align-left': {
								display: 'inline-flex',
							},
						}}
					>
						<DrivenLink
							icon={<DrivenIcon icon='arrow-left-long' size={IconSizes.MD} />}
							iconPlacement='left'
							variant='button'
							href='/news/'
							LinkComponent={GatsbyLink}
						>
							Back to Newsroom
						</DrivenLink>
					</DrivenBox>

					<DrivenTypography variant='body2' component='p' mb={1}>
						<time dateTime={dateStructured}>{dateFormatted}</time>
					</DrivenTypography>

					<DrivenTypography variant='h4' component='h1' mb={3}>
						{headline}
					</DrivenTypography>

					<DrivenTypography variant='subtitle2' component='h2' mb={3}>
						{subheadline}
					</DrivenTypography>

					<DrivenBox
						display='flex'
						flexWrap='nowrap'
						alignItems='center'
						columnGap={1}
						mb={3}
						sx={{
							a: {
								color: theme.palette.common.black,

								'&:hover': {
									color: theme.palette.tertiary?.dark,
								},
							},
						}}
					>
						<DrivenTypography variant='body2Bold' component='h3'>
							Share This Article:{' '}
						</DrivenTypography>

						<GatsbyLink
							href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
							target='_blank'
							onClick={(e) => {
								captureCustomDataLayerEvent('share', { method: 'linkedin' });
							}}
						>
							<DrivenIcon icon={IconTypes.LINKEDIN} size={IconSizes.MD} />
							<DrivenTypography component='span' sx={visuallyHidden}>
								LinkedIn
							</DrivenTypography>
						</GatsbyLink>

						<GatsbyLink
							href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
							target='_blank'
							onClick={(e) => {
								captureCustomDataLayerEvent('share', { method: 'facebook' });
							}}
						>
							<DrivenIcon icon={IconTypes.FACEBOOK} size={IconSizes.MD} />
							<DrivenTypography component='span' sx={visuallyHidden}>
								Facebook
							</DrivenTypography>
						</GatsbyLink>

						<GatsbyLink
							href={`http://www.twitter.com/share?url=${shareUrl}`}
							target='_blank'
							onClick={(e) => {
								captureCustomDataLayerEvent('share', { method: 'twitter' });
							}}
						>
							<DrivenIcon icon={IconTypes.TWITTER} size={IconSizes.MD} />
							<DrivenTypography component='span' sx={visuallyHidden}>
								Twitter
							</DrivenTypography>
						</GatsbyLink>

						<GatsbyLink
							href={`mailto:?subject=Sent from Driven Brands: ${headline}&amp;body=${shareUrl}`}
							target='_blank'
							onClick={(e) => {
								captureCustomDataLayerEvent('share', { method: 'email' });
							}}
						>
							<DrivenIcon icon={IconTypes.ENVELOPE} size={IconSizes.MD} />
							<DrivenTypography component='span' sx={visuallyHidden}>
								Email
							</DrivenTypography>
						</GatsbyLink>
					</DrivenBox>

					<ArticleImage image={image} alt={imageAlt} mb={3} />

					{body && (
						<MarkdownContent allowHtml={true}>
							{body.childMarkdownRemark.rawMarkdownBody}
						</MarkdownContent>
					)}
				</DrivenGrid>

				<DrivenGrid item md={3}>
					<DrivenTypography variant='h6' component='h2' mb={3}>
						Up Next
					</DrivenTypography>
					<DrivenBox
						component='aside'
						sx={{
							listStyleType: 'none',
							margin: 0,
							padding: 0,

							a: {
								display: 'block',
								textDecoration: 'none',
								color: theme.palette.common.black,

								'&:hover': {
									'.MuiTypography-subtitle1': {
										color: theme.palette.tertiary?.main,
									},
								},
							},
						}}
					>
						{latestArticles?.map((articleLink) => (
							<DrivenBox
								component='article'
								mx={0}
								mt={0}
								mb={3}
								p={0}
								key={`up-next-link-to-${articleLink.id}`}
							>
								<GatsbyLink
									href={articleLink.path}
									display='flex'
									flexdirection='column-reverse'
								>
									<DrivenTypography variant='subtitle1' component='h3'>
										{articleLink.headline}
									</DrivenTypography>

									<DrivenTypography variant='body2' component='p' mb={0.5}>
										<time dateTime={articleLink.dateStructured}>
											{articleLink.dateFormatted}
										</time>
									</DrivenTypography>
								</GatsbyLink>
							</DrivenBox>
						))}
					</DrivenBox>
				</DrivenGrid>
			</DrivenGrid>
		</DrivenContainer>
	);
};

export default ArticleTemplate;

export const Head = ({ data, pageContext }) => {
	const mainLogo = data?.mainLogo?.logo?.gatsbyImageData?.images?.fallback?.src || '';
	const { seo, heading, subheadline, image, createdAt, updatedAt, dateStructured } = data.article || {};
	const {
		name = '',
		title = '',
		description = '',
		scriptSrc,
		googleTagManager,
		noindex = false,
		nofollow = false,
		canonicalUrl = '',
	} = seo || {};

	const schemaTitle = seo?.title || heading;
	const blogUrl = `https://www.drivenbrands.com${pageContext.pagePath}`;
	const schemaDescription = seo?.description || subheadline ||	'';

	const schemaMarkup = {
		'@context': 'https://schema.org',
		'@type': 'BlogPosting',
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': blogUrl,
		},
		headline: schemaTitle,
		schemaDescription,
		image: image?.url || blogUrl,
		author: {
			'@type': "Organization",
			'name': 'Driven Brands',
		},
		publisher: {
			'@type': 'Organization',
			name: 'Driven Brands',
			logo: {
				'@type': 'ImageObject',
				url: mainLogo,
			},
		},
		datePublished: dateStructured || createdAt,
		dateModified: updatedAt,
	};

	return <>
		{seo && <SEO
			path={pageContext?.pagePath}
			title={title}
			description={description}
			noindex={noindex}
			nofollow={nofollow}
			scriptSrc={scriptSrc}
			googleTagManager={googleTagManager}
			ogImage={data.article?.image?.url}
			canonical={canonicalUrl}
			name={name}
		/>}
		<script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
		<Script
			id="embedly-platform-script"
			src="//cdn.embedly.com/widgets/platform.js"
			onLoad={() => embedly('card')}
		/>
	</>
};

export const data = graphql`
	query ArticleTemplateQuery($id: String) {
		article: contentfulArticle(id: { eq: $id }) {
			contentful_id
			id
			slug
			headline
			subheadline
			seo {
				name
				title
				description
				scriptSrc
				googleTagManager
				noindex
				nofollow
				canonicalUrl
			}
			createdAt(formatString: "YYYY-MM-DD")
			updatedAt(formatString: "YYYY-MM-DD")
			dateStructured: date(formatString: "YYYY-MM-DD")
			dateFormatted: date(formatString: "MMMM DD, YYYY")
			body {
				childMarkdownRemark {
					rawMarkdownBody
					html
				}
			}
			image {
				id
				url
				localFile {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 80)
					}
				}
			}
			imageAlt
		}
		mainLogo: contentfulNavigation {
			logo {
				gatsbyImageData(
						layout: CONSTRAINED
						formats: [AUTO, WEBP, AVIF]
						width: 155
					)
			}
		}
	}
`;
